@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Comfortaa.ttf) format("truetype");
}

body {
  overflow-y: scroll;
  font-family: Comfortaa;
}

body::-webkit-scrollbar,
body ::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

body::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  background: #8d96a2;
  border: 3px solid #f0f0f0;
  border-radius: 10px;
}

body::-webkit-scrollbar-track,
body ::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

a:active,
a:hover,
a {
  text-decoration: none;
}

.telegram-link {
  margin-top: -8px;
  margin-right: -6px;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

header {
  box-shadow: rgba(0, 0, 0, 0.13) 0 4px 16px;
  position: fixed;
  width: 100%;
  z-index: 3;
  background-color: #fff;
}

.header-container {
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

@media (max-width: 1024px) {
  .header-container {
    max-width: 95vw;
  }
}

.header-container a:hover:not(a:focus) {
  text-decoration: underline;
}

.burger a:hover:not(a:focus) {
  text-decoration: underline;
}

main {
  flex: 1 1 auto;
  padding: 80px 0 15px 0;
  display: flex;
  align-items: center;
}

.container {
  max-width: 70vw;
  margin: 0 auto;
}

.illustration {
  flex: 0 0 49.2%;
}

@media (max-width: 1024px) {
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 768px) {
  .illustration {
    flex: 1 1 auto;
  }
}

footer {
  background-color: #000;
  color: white;
}

.footer-container {
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

@media (max-width: 425px) {
  .footer-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding-bottom: 8px;
  }
}

.illustration img {
  object-fit: fill;
}

.about {
  display: flex;
  gap: 24px;
}

@media (max-width: 768px) {
  .about {
    flex-wrap: wrap;
  }
}

.menu-burger {
  width: 40px;
  height: 36px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  cursor: pointer;
}

.menu-burger div {
  width: 40px;
  height: 5px;
  background-color: #000;
}
